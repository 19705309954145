import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import "./ConfirmationStep.css";

const ConfirmationStep = ({ formik, selectedPack }) => {
    const { t } = useTranslation();
    const { handleSubmit } = formik;
    const packFrequencyMap = new Map([
        ["daily", 86],
        ["weekly", 88]
    ]);

    function getPrice(pack) {
        if (!pack) {
            return;
        }

        const amount = pack.pack_price;
        const frequency = t(packFrequencyMap.get(pack.pack_frequency))?.toLowerCase();

        return `${amount} Ar/${frequency}`;
    }

    useEffect(() => {
        setTimeout(() => {
            handleSubmit();
        }, [3000]);
    }, [handleSubmit]);

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/confirmation.svg" alt="login" />
            </div>

            <Form className="confirmation" onSubmit={formik.handleSubmit}>
                {selectedPack && (<>
                    <h3 className="title my-3 text-center">{t('89')}!</h3>
                    <p className="mx-auto my-0 text-center">
                        {formik.values.offerType === "Subscription" ? t('90') : t('96')}.
                    </p>
                    <p className="mx-auto my-0 text-center">
                        {t('95')}: {getPrice(selectedPack)}
                    </p>
                </>)}
                <p className="mx-auto my-3 text-center">
                    {t('91')}
                </p>
                <button className="btn btn-primary d-block mx-auto fw-bold" type="submit">
                    {t('79')}
                </button>
            </Form>
        </>
    )
}

export default ConfirmationStep;
