import { routes } from "constants/routes";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { authenticateWithISE2 } from "services/auth-api";
import Spinner from 'react-bootstrap/Spinner';
import { useSession } from "providers/SessionProvider";


const Register = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { updateToken, updateUser } = useSession();

    useEffect(() => {
        const ise2 = searchParams.get("token");

        if (ise2) {
            authenticateWithISE2(ise2).then((res) => {
                if (res.data?.isSubscribed) {
                    updateToken(res.data?.token);
                    navigate(routes.home);
                } else {
                    updateUser({ ...res.data, ise2code: ise2 });
                    navigate(routes.login);
                }
            }).catch(ex => {
                console.log(ex);
                navigate(routes.login);
            });
        } else {
            navigate(routes.login);
        }
    }, [searchParams, navigate, updateToken, updateUser]);

    return (
        <div className="register">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Register;
