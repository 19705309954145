import { router } from 'AppRoutes';
import './App.scss';
import "components/css/style.css";
import { RouterProvider } from 'react-router-dom';
import i18n from './utils/i18n';
import { I18nextProvider } from 'react-i18next';
import { SessionProvider } from 'providers/SessionProvider';

function App() {
  return (
      <I18nextProvider i18n={i18n}>
        <SessionProvider>
          <RouterProvider router={router} />
        </SessionProvider>
      </I18nextProvider>
  );
}

export default App;
