import api from "./interceptor";

export const sendOTP = async (data) => {
    try {
        const response = await api.post(`auth/notVasNetworkVasSendOtp`, data);
        const challengeUrl = response.data.location.location;

        return { ...response.data, challengeUrl };
    } catch (e) {
        console.log(e);
        return Promise.reject({ message: e.response.data.error });
    }
};

export const validateOTP = async (data) => {
    try {
        const response = await api.post(`auth/notVasNetworkVasValidateOtp`, data);

        const ise2 = response.data?.data?.challenge?.result?.[0].value;

        return { ...response.data, ise2 };
    } catch (e) {
        console.log(e);
        return Promise.reject({ message: e.response.data.error });
    }
};

export const subscribe = async (data) => {
    try {
        const response = await api.post(`auth/vasNetworkSubscribe`, data);

        return response.data;
    } catch (e) {
        console.log(e);
        return Promise.reject({ message: e.response.data.error });
    }
};

export const unsubscribe = async () => {
    try {
        const response = await api.post(`auth/vasNetworkUnSubscribe`);

        return response.data;
    } catch (e) {
        console.log(e.response.data);
        return Promise.reject({ message: e.response.data.error });
    }
};
