import { Form } from "react-bootstrap";
import "./ConfirmSubscription.css";
import { useTranslation } from "react-i18next";

const packFrequencyMap = new Map([
    ["daily", 107],
    ["weekly", 108]
]);

const ConfirmSubscription = ({ formik, selectedPack, changeStep }) => {
    const { t } = useTranslation();

    function getConfirmationString(pack) {
        if (!pack) {
            return;
        }

        const amount = pack.pack_price;
        const frequency = t(packFrequencyMap.get(pack.pack_frequency))?.toLowerCase();

        return [`${t('106')}`, `"Quiz Master ${Number.parseInt(amount)}ArTTC"`, `${t('102')} ${frequency} ${t('105')}`];
    }

    return (
        <>
            <div className="login-thumbnail2">
                <img src="/image/login_img.png" alt="login" />
            </div>

            <Form className="confirmation" onSubmit={formik.handleSubmit}>
                <h5 className="mx-auto my-3 text-center">
                    {getConfirmationString(selectedPack).map(text => (<p className="mx-auto my-0 text-center">
                        {text}
                    </p>))}
                </h5>
                <div className="button-container">
                    <button className="btn btn-primary d-block mx-auto fw-bold" type="button" onClick={() => changeStep(-1)}>
                        {t('98')}
                    </button>
                    <button className="btn btn-primary d-block mx-auto fw-bold" type="submit">
                        {t('78')}
                    </button>
                </div>
            </Form>
        </>
    )
}

export default ConfirmSubscription;
